.navbar {
  display: flex;
  justify-content: space-between;
  padding: 0rem 4rem;
}
.navbar-links {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.navbar-links-name {
  margin: 0rem 1rem;
  display: flex;
}
.navbar-links-name p {
  cursor: pointer;
  color: "#302F2F";
  font-weight: 700;
  font-size: 36px;
  line-height: 43px;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  font-style: normal;
}
.navbar_links_logo {
  display: flex;
  flex-direction: row;
}
.navbar-links_logo img {
  cursor: pointer;
  width: 55px;
  height: 57px;
}
.navbar-links_container {
  display: flex;
  flex-direction: row;
}
.navbar-signIn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.navbar-links_container p {
  margin: 1rem;
}
.navbar-links_container a {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.7);
  text-decoration: none;
  position: relative;
}
.navbar-links_container a::after {
  content: "  ";
  position: absolute;
  background-color: rgba(3, 172, 242, 1);
  height: 7px;
  width: 0px;
  border-radius: 3px;
  left: 0px;
  bottom: -10px;
  transition: 0.5s;
}
.navbar-links_container a:hover::after {
  width: 33px;
}
.navbar-signIn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.navbar-signIn p {
  margin: 0rem 2rem 0rem 0rem;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  color: rgba(48, 47, 47, 1);
  cursor: pointer;
}
.navbar-signIn p:hover {
  transform: scale(1.1);
}
.navbar-signIn button {
  padding: 0.5rem 2rem;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  border-radius: 20px;
  background: #4caf50;
  border: none;
  outline: none;
  cursor: pointer;
}
.navbar-signIn button:hover {
  background-color: green;
}
.navbar-signIn_vertical {
  margin: 2rem;
  height: 25px;
  width: 2;
  border: 1px solid rgba(151, 151, 151, 1);
}
.navbar-signIn img {
  cursor: pointer;
  height: 29px;
  width: 29px;
}
.navbar-signIn img:hover {
  transform: rotateZ(90deg);
}
@media screen and (max-width: 1050px) {
  .navbar {
    display: none;
  }
}
