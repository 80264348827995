.testimonials {
  display: flex;
  padding: 0rem 6rem;
}
.testimonials-container {
  border: 1px solid;
}
.testimonials-container_header h1 {
  font-size: 56px;
  margin: 0rem 0rem 2rem 0rem;
}
.testimonials-container_content {
  display: flex;
  flex-direction: row;
  border: 1px solid;
}
.testimonials-container_content-image {
  /* display: flex; */
  /* border: 1px solid; */
  /* flex: 1; */
  border: 1px solid;
}
.testimonials-container_content-image img {
  width: 50px;
  height: 50px;
}
