.clinics {
  /* display: flex; */
  padding: 1rem 6rem;
  /* border: 1px solid; */
  margin: 5rem 0rem;
  background: #f2f2f2;
}
.clinics-container {
  /* border: 1px solid; */
  display: flex;
  flex-direction: column;
}

.clinics-bubble_container {
  /* border: 1px solid; */
  margin-top: 2rem;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0rem;
  display: grid;
  row-gap: 25px;
  column-gap: 90px;
  grid-template-columns: auto auto;
}
.clinics-bubble {
  background-color: rgb(255, 255, 255);
  display: flex;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  padding: 1rem 01rem;
  flex-direction: row;
}
.clinics-bubble_image {
  display: flex;
}
.clinics-bubble_image img {
  width: 150px;
  height: 150px;
}
.clinics-bubble_phone {
  display: flex;
  margin: 0.5rem;
  flex-direction: row;
  justify-content: space-between;
}
.clinics-bubble_content {
  justify-content: space-between;
  padding: 0rem 0.5rem;
}
.clinics-bubble_container-btn {
  margin-top: 1.8rem;
  justify-content: center;
  display: flex;
}
.clinics-bubble_container-btn button {
  outline: none;
  border: 1px solid green;
  color: green;
  cursor: pointer;
  background-color: white;
  border-radius: 25px;
  margin-top: 0.5rem;
  padding: 0.5rem 6rem;
}
.clinics-bubble_container-btn button:hover {
  background-color: green;
  color: white;
}
