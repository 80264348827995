.header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.header-content {
  flex: 1;
  margin: 0rem 4rem;
  display: flex;
  flex-direction: column;
  padding: 0rem;
}
.header-content h1 {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  margin: 1rem 0rem;
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 58px;
  color: rgba(0, 0, 0, 0.87);
}
.header-content h2 {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 48px;
  color: rgba(0, 0, 0, 0.54);
}
.header-content_button-container {
  margin: 1rem 0rem;
  align-items: center;
  flex-direction: row;
  display: flex;
}
.header-Image {
  flex: 1.5;
  display: flex;
}
.header-Image img {
  width: 100%;
  height: 100%;
}
.header-content_button-container button {
  padding: 0.3rem 1.2rem;
  border-radius: 24px;
  outline: none;
  border: none;
  background: #03acf2;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  font-style: normal;
  font-weight: 700;
  line-height: 29px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  margin: 0rem 1rem;
  cursor: pointer;
}
.header-content_button-container button:hover {
  background-color: #4dc3ff;
}
.header-content_button-container p {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  text-transform: uppercase;
  color: #357a38;
}
@media screen and (max-width: 1050px) {
  .header {
    flex-direction: column;
  }
}
@media screen and (max-width: 430px) {
  .header-content_button-container button {
    padding: 0rem 2rem;
  }
}
