.specialty {
  display: flex;
  padding: 1rem 6rem;
  /* border: 1px solid; */
  margin: 5rem 0rem;
}
.specialty-container {
  display: flex;
  flex-direction: row;
}
.specialty-container_left {
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  display: flex;
  /* border: 1px solid; */
  flex: 1.5;
}
.specialty-container_left h1 {
  font-size: 50px;
}
.specialty-container_left-paragraph {
  margin: 1rem 0rem;
  /* border: 1px solid; */
  display: flex;
  width: 600px;
  height: 100px;
}
.specialty-container_left-paragraph p {
  font-size: 20px;
}

.specialty-bubble_container {
  padding: 0.5rem 0rem;
  /* flex: 0.5; */

  display: grid;
  row-gap: 15px;
  column-gap: 10px;
  grid-template-columns: auto auto auto;
}
.specialty-bubble {
  color: rgba(0, 0, 0, 0.54);
  display: flex;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  justify-content: center;
  align-items: center;
}
.specialty-bubble:hover {
  cursor: pointer;
  color: rgba(255, 255, 255, 1);
  background-color: rgba(3, 172, 242, 1);
}
.specialty-container_right {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0.7;
}
.specialty-container_right img {
  width: 100%;
  height: 100%;
}
