.achievements {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  /* border: 1px solid; */
  padding: 0rem 5rem;
}
.achievements-sub_container {
  flex: 1;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  /* border: 1px solid; */
  cursor: pointer;
}
.achievements-sub_container:hover {
  transform: scale(1.05);
}
.achievements-sub_image {
  display: flex;
}
.achievements-sub_image img {
  width: 100%;
  height: 73px;
}
.achievements-sub_text {
  margin-left: 15px;
  flex-direction: column;
  align-items: flex-start;
}
.achievements-sub_text h1 {
  /* border: 1px solid; */

  font-weight: 900;
  font-size: 48px;
  line-height: 56px;
  font-style: normal;
  color: #357a38;
}
.achievements-sub_text h2 {
  /* border: 1px solid;  */
  /* font-family: "Lato"; */
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  /* line-height: 56px; */

  color: rgba(0, 0, 0, 0.87);
}
/* .achievements-sub_textTwo {
  flex: 1.5;
  display: flex;
} */
@media screen and (max-width: 1050px) {
  .achievements {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .achievements-sub_container {
    margin: 1rem 0rem;
  }
}
