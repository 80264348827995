.facilities {
  display: flex;
  /* border: 1px solid; */
  flex-direction: column;
}
.facilities-top {
  /* border: 1px solid; */
  margin: 5rem 6rem;
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: space-between;
}
.facilities-top_first {
  flex: 2;
  flex-direction: column;
  display: flex;
}
.facilities-top h3 {
  margin: 1rem 0rem;
  font-size: 20px;
  color: rgba(0, 0, 0, 0.7);
  font-weight: 700;
}
.facilities-top h1 {
  font-size: 50px;
}
.facilities-top_two {
  justify-content: center;
  align-items: center;
  /* border: 1px solid; */
  /* flex-direction: row; */
  display: flex;
}
.facilities-top_two button {
  cursor: pointer;
  outline: none;
  border: none;
  background-color: transparent;
  color: rgba(0, 87, 155, 1);
  font-weight: 700;
  font-size: 28px;
  line-height: 42px;
}
.facilities-top_two-icon {
  color: rgba(0, 87, 155, 1);
  font-size: 50px;
}
.facilities-bottom {
  display: flex;
  /* border: 1px solid; */
}
.facilities-bottom_image {
  display: flex;
  padding: 0rem 0rem 3rem 6rem;
  flex: 1.2;
}
.facilities-bottom_image img {
  width: 100%;
  height: 100%;
}
.facilities-bottom_card {
  display: flex;
  flex: 1.7;
  justify-content: space-between;
  align-items: space-between;
  padding: 0rem 3rem 0rem 2rem;
}
.facilities-bottom_card-one {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  height: 100%;
  width: 230px;
  background-color: white;
  /* border: 1px solid; */
  border-radius: 20px;
}
.facilities-bottom_card-image {
  display: flex;
  border-radius: 20px;
}
.facilities-bottom_card-image img {
  width: 100%;
  border-bottom-right-radius: 50px;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  height: 160px;
}
.facilities-bottom_card-content {
  display: flex;
  flex-direction: column;
  padding: 0rem 0rem 0rem 1rem;
}
.facilities-bottom_card-content-bottom_container {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.facilities-bottom_card-content-bottom {
  border-top-left-radius: 25px;
  border-bottom-right-radius: 25px;
  margin: 2rem 0rem 0rem 0rem;
  width: 50%;
  background-color: green;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.facilities-bottom_card-content-bottom:hover {
  background-color: #009933;
}
.facilities-bottom_card-content h3 {
  font-weight: 500;
  font-size: 23px;
  margin: 1rem 0rem;
}
.facilities-bottom_card-content-bottom h3 {
  font-size: 20px;
  font-weight: 400;
  text-transform: capitalize;
  color: white;
}

@media screen and (max-width: 770px) {
  .facilities-bottom_card-content-bottom {
    flex-direction: column;
  }
}
@media screen and (max-width: 850px) {
  .facilities-top {
    margin: 1rem 1rem;
  }
  .facilities-top h1 {
    font-size: 30px;
  }
  .facilities-top h3 {
    font-size: 20px;
  }
  .facilities-top_two button,
  .facilities-top_two-icon {
    /* display: block; */
    font-size: 15px;
  }
}
@media screen and (max-width: 480px) {
  .facilities-bottom {
    flex-direction: column;
  }
  .facilities-bottom_card {
    display: flex;
    padding: 0rem;
  }
}
