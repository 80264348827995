.appointment {
  display: flex;
  padding: 2.5rem 2rem;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(118.17deg, #03acf2 6.86%, #c8e6c9 103.41%);
  margin: 6rem;
  border-radius: 24px;
}
.appointment-container {
  flex-direction: column;
}
.appointment-container h1,
h2 {
  color: rgba(255, 255, 255, 1);
  margin: 0rem 0rem 0.5rem 0rem;
}
.appointment-container_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 4rem;
}
.appointment-container_btn button {
  border: none;
  outline: none;
  background-color: rgba(255, 255, 255, 1);
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  text-transform: uppercase;
  text-align: center;
  color: rgba(3, 172, 242, 1);
  padding: 1rem;
  border-radius: 34.5px;
  cursor: pointer;
}
.appointment-container_btn button:hover {
  transform: scale(1.1);
}
