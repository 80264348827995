.question {
  display: flex;
  padding: 0rem 0rem 0rem 6rem;
}
.question-container {
  /* justify-content: space-between; */
  /* border: 1px solid; */
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: row;
}
.question-container_left {
  /* display: flex; */
  flex-direction: column;
  display: flex;
  height: 500px;
  flex: 2;
}
.question-container_left h1 {
  font-size: 55px;
  width: 100px;
  margin: 0rem 0rem 2rem 0rem;
}
.question-link_container {
  flex-direction: column;
}
.question-link_container p {
  margin: 2rem 0rem;
  padding: 0.2rem 0.5rem;
}
.question-link_container p:hover {
  width: 200px;
  padding: 0.2rem 0.5rem;
  border-radius: 20px;
  /* margin: 2rem 0rem; */
  background-color: rgba(179, 229, 252, 1);
}
.question-link_container a {
  font-size: 25px;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.87);
}
.question-link_container a:hover {
  color: rgba(1, 87, 155, 1);
}
.question-container_right {
  flex: 5;
  position: relative;
  top: 0;
  left: 0;
  display: flex;
  /* border: 1px solid; */
}
.question-container_right img {
  position: absolute;
  top: 30px;
  left: 30px;
  width: 800px;
  height: 600px;
}
.question-container_right-background img {
  position: relative;
  top: 340px;
  left: 600px;
  width: 400px;
  height: 300px;
}
