.main {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  /* border: 1px solid?; */
  padding: 3rem 0rem;
}
.main-content {
  display: flex;
  justify-content: center;
  align-items: center;
}
.main-content_sub {
  margin: 0rem 2rem;
  cursor: pointer;
  position: relative;
}
.main-content_sub::after {
  margin: 0.5rem 0rem;
  content: "  ";
  position: absolute;
  background-color: rgba(3, 172, 242, 1);
  height: 6px;
  width: 0px;
  border-radius: 3px;
  transition: 0.5s;
}
.main-content_sub:hover::after {
  width: 135px;
}
.main-content_sub:hover h2 {
  color: rgba(0, 87, 155, 1);
  font-size: 30px;
  transition: 0.5s;
}
.main-content_sub h2 {
  font-weight: 700;
  font-size: 28px;
  margin: 0rem 0rem 1rem 0rem;
  color: black;
}
.main-content_sub p {
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
}
.main-vertical {
  height: 50px;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
@media screen and (max-width: 430px) {
  .main-content {
    display: inline;
  }
  .main-vertical {
    display: none;
  }
  .main-content_sub {
    margin: 2rem 0rem;
  }
}
